import React from "react"
import Seo from "../../components/SEO"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import GalleryPage from "../../components/Portfolios/GalleryPage"
import paths from "../../constants/paths"

const Container = styled.div``;

const imagesQuery = graphql`
  fragment fluidWeddingPortfolioImage on File {
    name
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 1400)
    }
    childrenImageSharp {
      gatsbyImageData(
        layout: CONSTRAINED,
        width: 2880
      )
    }
  }

  query WeddingPortfolioImages {
    allFile(filter:{
      relativePath:{glob:"portfolios/weddings/*"}
    },
    sort: {fields: [id], order: ASC}) {
      edges {
        node {
          id,
          name,
          ...fluidWeddingPortfolioImage
        }
      }
    }
  }
`

const WeddingPortfolio = ({ location }) => {
  const imagesQueryResult = useStaticQuery(imagesQuery)

  return (
    <Container>
      <Seo title={paths.portfolioWeddings.title} location={location} />
      <GalleryPage imagesQueryResult={imagesQueryResult} />
    </Container>
  )
}

export default WeddingPortfolio
